import './static/fonts.css'
import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
    palette: {
        background: {
            default: '#fff',
        },
    },
    overrides: {
      MuiContainer: {
          root: {
            paddingTop: '3rem',
            paddingBottom: '4rem',
          },
      },
      MuiCard: {
          root: {
              backgroundColor: '#F8F9FA',
          }
      }
    },
});
export const wrapRootElement = ({ element }) => {
    return (
        <>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                {element}
            </ThemeProvider>
          </StyledEngineProvider>
        </>
    );
};